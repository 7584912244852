import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'


class BlogPost extends Component {
    render() {
      // console.log('PROPS', this.props) // Props that passed from gatsby-node
        const { title, slug } = this.props.data.contentfulBlogPost
        return (
            <div>
                <h1>{title}</h1>
                <p>{slug}</p>
            </div>
        )
    }
}

BlogPost.propTypes = {
    data: PropTypes.object.isRequired
}

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
        id
    }
  }
`